<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">数据采集应用</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <div class="engineering plan" @click="toFactory">
                    <span class="planTitle">工业无线数传</span>
                </div>
                <div class="guide" @click="toWarehouse">
                    <span class="planTitle">物流无线覆盖传输</span>
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    },
    methods: {
        toFactory() {
            this.$router.push('mSolutionFactoryMore')
        },
        toWarehouse() {
            this.$router.push('mSolutionWarehouseMore')
        }
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
    background-size: 230%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 750px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    background-color: #ffffff;
}

.container .containerBox .plan {
    margin-bottom: 100px;
}

.container .containerBox .engineering {
    position: relative;
    width: 100%;
    height: 1000px;
    background: url(../../../assets/mobile/img/solution/factory.jpg) center no-repeat;
    background-size: 110%;
    box-shadow: 10px 20px 50px 1px rgb(32 32 32 / 30%);
}

.container .containerBox .guide {
    position: relative;
    width: 100%;
    height: 1000px;
    background: url(../../../assets/mobile/img/solution/warehouse.jpg) center no-repeat;
    background-size: 110%;
    box-shadow: 10px 20px 50px 1px rgb(32 32 32 / 30%);
}

.container .containerBox .engineering .planTitle {
    position: absolute;
    left: 50px;
    bottom: 50px;
    font-weight: 700;
    font-size: 70px;
}

.container .containerBox .guide .planTitle {
    position: absolute;
    left: 50px;
    bottom: 50px;
    font-weight: 700;
    font-size: 70px;
}

.container .containerBox .document .planTitle {
    position: absolute;
    left: 50px;
    bottom: 50px;
    font-weight: 700;
    font-size: 70px;
}
</style>